import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LandingPage from "../components/landingPage/LandingPage";
import BookingForm from "../commonComponents/BookingForm";
import CheckSlots from "../components/OwnerView/CheckSlots";
import RoleSelect from "../commonComponents/RoleSelect";
import Signup from "../components/Registration/Signup";
import Login from "../components/Registration/Login";
import OwnerLandingPage from "../components/OwnerView/ownerLandingPage/OwnerLandingPage";

const Router = () =>{
    
    return(
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<RoleSelect/>} />
                <Route path="/userHome" element={<LandingPage/>} />
                <Route path="/booking" element={<BookingForm/>} />
                <Route path="/checkSlots/:ground_id" element={<CheckSlots/>} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/login" element={<Login/>} />
                <Route path="/ownerLandingPage" element={<OwnerLandingPage />} />
            </Routes>
        </BrowserRouter>
    )
}
export default Router;