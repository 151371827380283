import React, { useEffect, useState } from "react";
import "./checkSlots.css";
import { Col, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";

const CheckSlots = () => {
  const { ground_id } = useParams();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [slotsData, setSlotsData] = useState([]);
  const [slotBooked, setSlotBooked] = useState(false);

  // State to manage input fields, showDetails, and editMode for each slot
  const [slotInputs, setSlotInputs] = useState({
    1: { team1Name: "", team2Name: "", team1CaptainName: "", team1CaptNumber: "", team2CaptainName: "", team2CaptNumber: "", showDetails: false, editMode: false },
    2: { team1Name: "", team2Name: "", team1CaptainName: "", team1CaptNumber: "", team2CaptainName: "", team2CaptNumber: "", showDetails: false, editMode: false },
    3: { team1Name: "", team2Name: "", team1CaptainName: "", team1CaptNumber: "", team2CaptainName: "", team2CaptNumber: "", showDetails: false, editMode: false },
    4: { team1Name: "", team2Name: "", team1CaptainName: "", team1CaptNumber: "", team2CaptainName: "", team2CaptNumber: "", showDetails: false, editMode: false },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://new.bookmygrounds.in/slotsData?date=${selectedDate.toISOString().split("T")[0]}&ground_id=${ground_id}`
        );
        const data = await response.json();
        if (data.message) {
          setSlotsData(data.message);
        } else {
          setSlotsData([]);
        }
      } catch (error) {
        console.error("Error fetching slots data:", error);
      }
    };
    fetchData();
  }, [selectedDate, ground_id]);

  useEffect(() => {
    // Load slotData into slotInputs only once after data is fetched
    // if (slotsData.length > 0) {
      const newSlotInputs = {};
      [1, 2, 3, 4].forEach((slotId) => {
        const slotData = getSlotData(slotId) || {};
        newSlotInputs[slotId] = {
          team1Name: slotData.booked_team1_name || "",
          team2Name: slotData.booked_team2_name || "",
          team1CaptainName: slotData.booked_team1_captainName || "",
          team1CaptNumber: slotData.booked_team1_contact || "",
          team2CaptainName: slotData.booked_team2_captainName || "",
          team2CaptNumber: slotData.booked_team2_contact || "",
          showDetails: false,
          editMode: false,
        };
      });
      setSlotInputs(newSlotInputs);
    
  }, [slotsData]);

  // Handle input changes
  const handleInputChange = (e, slotId, field) => {
    const { value } = e.target;
    setSlotInputs((prevInputs) => ({
      ...prevInputs,
      [slotId]: {
        ...prevInputs[slotId],
        [field]: value,
      },
    }));
  };

  // Handle booking a slot
  const handleBookSlot = (slot) => {
    const slotData = slotInputs[slot];
    const data = {
      date: selectedDate.toISOString(),
      slot,
      ground_id,
      ...slotData,
    };

    fetch("https://new.bookmygrounds.in/booking", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          setSlotBooked(true);
          Swal.fire({
            icon: "success",
            title: "Booking Successful",
            text: `Slot ${slot} booked successfully for ${slotData.team1Name} and ${slotData.team2Name}`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Booking Failed",
            text: `Failed to book slot ${slot} for ${slotData.team1Name} and ${slotData.team2Name}`,
          });
        }
      })
      .catch((error) => {
        console.error("Error booking slot:", error);
      });
  };

  // Handle updating an existing booking for a slot
  const handleUpdateBooking = (slot) => {
    const slotData = slotInputs[slot];
    const data = {
      date: selectedDate.toISOString().split("T")[0],
      slot,
      ground_id,
      ...slotData,
    };

    fetch("https://new.bookmygrounds.in/bookingUpdate", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          Swal.fire({
            icon: "success",
            title: "Booking Updated",
            text: `Slot ${slot} updated successfully.`,
          });
          setSlotInputs((prevInputs) => ({
            ...prevInputs,
            [slot]: { ...prevInputs[slot], editMode: false },
          }));
        } else {
          Swal.fire({
            icon: "error",
            title: "Update Failed",
            text: `Failed to update slot ${slot}.`,
          });
        }
      })
      .catch((error) => {
        console.error("Error updating booking:", error);
      });
  };

  // Toggle details view for each slot
  const toggleDetails = (slotId) => {
    setSlotInputs((prevInputs) => ({
      ...prevInputs,
      [slotId]: {
        ...prevInputs[slotId],
        showDetails: !prevInputs[slotId].showDetails,
      },
    }));
  };

  // Toggle edit mode for each slot
  const toggleEditMode = (slotId) => {
    const { editMode } = slotInputs[slotId];
    if (editMode) {
      handleUpdateBooking(slotId);
    } else {
      setSlotInputs((prevInputs) => ({
        ...prevInputs,
        [slotId]: {
          ...prevInputs[slotId],
          editMode: true,
        },
      }));
    }
  };

  // Get booked data for a specific slot
  const getSlotData = (slotId) => slotsData.find((slot) => slot.slot_id === slotId);

  return (
    <>
      <Row className="date-tab text-center">
        <Col md={3}>
          <DatePicker
            className="border-less"
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            dateFormat="EEE, MMM d, yyyy"
            placeholderText={format(new Date(), "EEE, MMM d, yyyy")}
          />
        </Col>
      </Row>

      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <ul className="list-group">
              {[1, 2, 3, 4].map((slotId) => {
                const slotData = getSlotData(slotId) || {};
                const { showDetails, editMode } = slotInputs[slotId];

                return (
                  <li key={slotId} className="list-group-item-checkslots">
                    <div className="slotName-container">
                      <span className="lead font-weight-bold">
                        Slot {slotId} : {slotId === 1 ? "7:30AM to 10:30PM" : slotId === 2 ? "11AM to 2PM" : slotId === 3 ? "2:30PM to 5:30PM" : "6PM to 9PM"}
                      </span>
                      {slotData.status === 1 && (
                        <button
                          className="btn btn-sm btn-secondary m-2"
                          onClick={() => toggleEditMode(slotId)}
                        >
                          {editMode ? "Save Changes" : "Edit Slot"}
                        </button>
                      )}
                    </div>

                    <div className="d-flex flex-sm-row mt-1">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Team 1 Name"
                        value={slotInputs[slotId].team1Name}
                        onChange={(e) => handleInputChange(e, slotId, "team1Name")}
                        disabled={slotData.status === 1 && !editMode}
                      />
                      <small className="p-2 vs">vs</small>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Team 2 Name"
                        value={slotInputs[slotId].team2Name}
                        onChange={(e) => handleInputChange(e, slotId, "team2Name")}
                        disabled={slotData.status === 1 && !editMode}
                      />
                    </div>

                    {showDetails && (
                      <div className="details mt-2">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Team 1 Captain Name"
                          value={slotInputs[slotId].team1CaptainName}
                          onChange={(e) => handleInputChange(e, slotId, "team1CaptainName")}
                          disabled={slotData.status === 1 && !editMode}
                        />
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Team 1 Captain Contact"
                          value={slotInputs[slotId].team1CaptNumber}
                          onChange={(e) => handleInputChange(e, slotId, "team1CaptNumber")}
                          disabled={slotData.status === 1 && !editMode}
                        />
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Team 2 Captain Name"
                          value={slotInputs[slotId].team2CaptainName}
                          onChange={(e) => handleInputChange(e, slotId, "team2CaptainName")}
                          disabled={slotData.status === 1 && !editMode}
                        />
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Team 2 Captain Contact"
                          value={slotInputs[slotId].team2CaptNumber}
                          onChange={(e) => handleInputChange(e, slotId, "team2CaptNumber")}
                          disabled={slotData.status === 1 && !editMode}
                        />
                      </div>
                    )}
<div className="d-flex justify-content-between mt-2">
  <button className="btn btn-dark" onClick={() => toggleDetails(slotId)}>
    {showDetails ? "Hide Details" : "Add More Info"}
  </button>
  {slotData.status !== 1 && (
    <button className="btn btn-dark" onClick={() => handleBookSlot(slotId)}>
      Book
    </button>
  )}
</div>                    
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckSlots;
