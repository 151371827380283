// import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Router from './container/commonRouter/router';
import { useState } from 'react';

function App() {
  
  return (
    <>

      <Router/>
    </>
  );
} 
 
export default App;
