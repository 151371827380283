import React, { useState } from "react";
import background from "../../../sportsBackground.jpg";
import {Row, Col} from "react-bootstrap"
import "./SearchComponent.css";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

function SearchComponent(){
    const divStyle = {
        position:'relative',
        background: `url(${background}) center/cover fixed no-repeat`,
        // filter: 'blur(5px)', // Adjust the blur amount as needed
        height: '50vh', // Set the height to cover the entire viewport
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white', // Text color on top of the blurred background
      };

      const textOverlayStyle = {
        position: 'absolute',
        textAlign: 'center',
      };

      const [selectedDate, setSelectedDate] = useState('');

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };
    return(
        <>
            <div className="search-component" >
                <div className="background" style={divStyle}>
                <div style={textOverlayStyle}>
                    <Row className="search-tab">
                        <Col md={3} className="search-col">
                            <select>
                                <option>Delhi NCR</option>
                                <option>Bangalore</option>
                                <option>Mumbai</option>
                            </select>
                        </Col>
                        <Col md={3} className="search-col">
                            <select>
                                <option>Cricket</option>
                                <option>Soccer</option>
                                <option>Badminton</option>
                            </select>
                        </Col>
                        <Col md={3} className="search-col">
                        <input
        type="date"
        id="dateInput"
        value={selectedDate}
        onChange={handleDateChange}
      />
                        </Col>

                    <Col md={3} className="search-col-search">
                        <SearchOutlinedIcon/>
                        <button>Search</button>

                    </Col>
                    </Row>
        
                </div>
                </div>

            </div>
        </>
    )
}
export default SearchComponent;