import React from "react";
import "./Signup.css";
import GoogleLogo from "./google_logo.svg";
import loginImage from "./loginPgae.png";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

function Login() {
  const navigate = useNavigate();
  const { loginWithRedirect, isAuthenticated, user } = useAuth0();

  const handleLoginClick =( e)=>{
    e.preventDefault();
    navigate("/ownerLandingPage")
  } 

  return (
    <>
      <div className="container">
        <div className="row py-1 align-items-center">
          <div className="col-md-5 pr-lg-5  mb-md-0 pr-sm-0">
            <img src={loginImage} alt="" className="img-fluid mb-3 d-md-block" />
            {/* <div className="title-div">
                        <h1 className="login-text">Login to <span>BookMyGround</span></h1>
                        </div> */}
          </div>

          <div className="col-md-7 col-lg-6 ml-auto ">
            <form action="#">
              <div className="row">
                <div className="input-group col-lg-12 mb-4">
                  {/* <div className="input-group-prepend">
                            <span className="input-group-text bg-white px-4 border-md border-right-0">
                                <i className="fa fa-envelope text-muted"></i>
                            </span>
                        </div> */}
                  <input
                    id="email"
                    type="email"
                    name="email"
                    placeholder="Email Address"
                    className="form-control bg-white border-left-0 border-md"
                  />
                </div>

                <div className="input-group col-lg-6 mb-4">
                  {/* <div className="input-group-prepend">
                            <span className="input-group-text bg-white px-4 border-md border-right-0">
                                <i className="fa fa-lock text-muted"></i>
                            </span>
                        </div> */}
                  <input
                    id="password"
                    type="password"
                    name="password"
                    placeholder="Password"
                    className="form-control bg-white border-left-0 border-md"
                  />
                </div>

                {/* <div className="form-group col-lg-12  mb-0 col-sm-12 ">
                                    <a href="#" className="btn btn-primary btn-block py-2 px-5">
                                        <span className="font-weight-bold create-account mx-3" onClick={()=>navigate("/ownerLandingPage")}>Login</span>
                                    </a>
                                </div> */}

                <div className="form-group col-lg-12  mb-0 col-sm-12">
                  <button onClick={handleLoginClick}
    
                    className="btn btn-primary btn-block py-2 create-account-btn"
                  >
                    <span className="font-weight-bold create-account" >
                      Login
                    </span>
                  </button>
                </div>

                <div className="form-group col-lg-12 mx-auto d-flex align-items-center my-4">
                  <div className="border-bottom w-100 ml-5"></div>
                  <span className="px-2 small text-muted font-weight-bold text-muted">
                    OR
                  </span>
                  <div className="border-bottom w-100 mr-5"></div>
                </div>

                <div className="form-group col-lg-12 mx-auto col-md-12 col-sm-12 col-xs-12 ">
                  <a className="btn  btn-block btn-outline-secondary py-2 d-flex align-items-center btn-google google-signin">

                    <span
                      className="font-weight-bold flex-grow-1  text-center"
                      onClick={(e) =>
                        loginWithRedirect({
                          authorizationParams: {
                            connection: "google-oauth2",
                          },
                        })
                      }
                    >
                    <img src={GoogleLogo} width="30" height="30" className="mr-3"/>
                      Continue with Google
                    </span>
                  </a>
                </div>

                <div className="text-center w-100 pt-1">
                  <p className="text-muted font-weight-bold ">
                    Don't Have an Account?{" "}
                    <a
                      className="text-primary ml-3"
                      onClick={() => navigate("/signup")}
                    >
                      SignUp
                    </a>
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
