import React from "react";
import MyNavbar from "../../commonComponents/Navbar/MyNavbar";
import SearchComponent from "../SearchComponent/SearchComponent";
import CardCarousel from "../CardCarousel/CardCarousel";

const LandingPage =()=>{
    return(
        <div>
            <MyNavbar/>
            <SearchComponent />
            <CardCarousel/>
        </div>
    )
}
export default LandingPage;