import React, { useState } from "react";
import LandingPage from "../components/landingPage/LandingPage";
import OwnerLandingPage from "../components/OwnerView/ownerLandingPage/OwnerLandingPage";
import Signup from "../components/Registration/Signup";
import { useNavigate } from "react-router-dom";

const RoleSelect=()=>{
    const navigate = useNavigate()
    const [selectedPage, setSelectedPage] = useState(null);

    return(
        <>
        <button onClick={()=>setSelectedPage(1)}>User</button>
        <button onClick={()=>navigate("/signup")}>Owner</button>
        {
            selectedPage === 1 && <LandingPage/>
        }{
            // selectedPage === 2 && {()=>navigate("/signup")};
        }
        </>
    )
}
export default RoleSelect;