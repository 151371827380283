import React, { useState } from "react";
import "./GroundDetailsForm.css";

const GroundDetailsForm = ({ onClose }) => {
  const [formData, setFormData] = useState({
    GroundName: "",
    GroundsMenName: "",
    phone: "",
    location: "",
    locationCity: "",
    locationState: "",
    email: "",
    pics: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleAddDetailsClick = async (event) => {
    //event.preventDefault();
    try {
      console.log("Form Data Collected:", formData);

      const response = await fetch(
        "https://new.bookmygrounds.in/groundDetails",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit form");
      }

      console.log("Form submitted successfully");
      onClose();
    } catch (error) {
      console.error("Error submitting form:", error.message);
    }
  };

  return (
    <>
      <div className="form-popup">
        <div className="form-container">
          <h3 className="text-center pb-2" style={{color:"#45474B"}}>Enter your Ground Details</h3>
          <form id="groundDetailsForm">
            <div className="mb-3">
              <label for="GroundName" className="form-label">
                Ground Name
              </label>
              <input
                id="GroundName"
                type="text"
                name="GroundName"
                className="form-control"
                placeholder="Enter Ground Name"
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-3">
              <label for="GroundsMenName" className="form-label">
                Grounds Men Name
              </label>
              <input
                id="GroundsMenName"
                type="text"
                name="GroundsMenName"
                className="form-control"
                placeholder="Enter Grounds Men Name"
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-3">
              <label for="phone" className="form-label">
                Phone Number
              </label>
              <input
                id="phone"
                type="tel"
                name="phone"
                className="form-control"
                placeholder="Enter Phone Number"
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-3">
              <label for="location" className="form-label">
                Ground's Location
              </label>
              <input
                id="location"
                type="text"
                name="location"
                className="form-control"
                placeholder="Enter Ground's Location"
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-3">
              <label for="locationCity" className="form-label">
                Ground's City
              </label>
              <input
                id="locationCity"
                type="text"
                name="locationCity"
                className="form-control"
                placeholder="Enter Ground's City"
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-3">
              <label for="locationState" className="form-label">
                Ground's State
              </label>
              <input
                id="locationState"
                type="text"
                name="locationState"
                className="form-control"
                placeholder="Enter Ground's State"
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-3">
              <label for="email" className="form-label">
                Email
              </label>
              <input
                id="email"
                type="email"
                name="email"
                className="form-control"
                placeholder="Enter Email"
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-3">
              <label for="pics" className="form-label">
                Upload Picture
              </label>
              <input
                id="pics"
                type="file"
                name="pics"
                className="form-control"
                onChange={handleInputChange}
              />
            </div>
            <div className="d-grid gap-2 row">
              <button
                type="button"
                className="btn btn-primary me-md-2 mb-2 mb-md-0 col-sm-12 "
                onClick={handleAddDetailsClick}
              >
                Add Details
              </button>
              <button type="button" className="btn btn-secondary col-sm-12" onClick={onClose}>
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default GroundDetailsForm;
