import React, { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./OwnerLandingPage.css";
import GroundDetailsForm from "./GroundDetailsForm";
import { useAuth0 } from "@auth0/auth0-react";
import MyNavbar from "../../../commonComponents/Navbar/MyNavbar";

const OwnerLandingPage = ({sharedData}) => {
    const [isFormVisible, setIsFormVisible] = useState(false);
    const {isAuthenticated,user} = useAuth0();
    const [grounds, setGrounds] = useState([]);
    const userData = localStorage.getItem("userData");
    const parsedData = JSON.parse(userData);

    // const YourComponent = () => {
    //     const [grounds, setGrounds] = useState([]);
    //     const [isFormVisible, setIsFormVisible] = useState(false);
    //    // const navigate = useNavigate();
    
    useEffect(() => {
        // Fetch ground names from API
        fetch('https://new.bookmygrounds.in/groundList', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Email': parsedData?.email
              },
          })
            .then(response => response.json())
            .then(data => {
                setGrounds(data);
                console.log(data);
            })
            .catch(error => {
                console.error('Error fetching ground names:', error);
            });
    }, []);

    const handleOpen = ()=>{
        setIsFormVisible(true)
    }

    const handleClose = ()=>{
        setIsFormVisible(false)
    }
    const handleItemClick = (ground_id) => {        
        navigate(`/checkSlots/${ground_id}`);
    };
    const navigate = useNavigate();
    return (
        <>
        <MyNavbar/>
            
            <ul className="list-group">
            {grounds.map((ground, index) => (
                <li key={index} className="list-group-item" onClick={()=>handleItemClick(ground.ground_id)}>{ground.ground_name}</li>
            ))}
            {/* <li className="list-group-item">Ground 2</li>
            <li className="list-group-item">Ground 3</li> */}
        </ul>
            <button class="btn btn-primary fixed-bottom-button" onClick={()=>handleOpen()}>Add Ground Details</button>
            {isFormVisible && <GroundDetailsForm onClose={handleClose}/>}
            
        
            {isFormVisible && <GroundDetailsForm onClose={handleClose} />}
        </>
    )
}

export default OwnerLandingPage;
