import React from 'react';
import { Carousel, Card, Container,Button  } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './CardCarousel.css'
import { useNavigate } from 'react-router-dom';
const CardCarousel = () => {
    // const navigate = useNavigate();
    // const handleBookClick =() =>{
    //     navigate("/booking")
    // }
  return (
    <Container>
    <Carousel  className="custom-carousel">
      <Carousel.Item>
        <Card>
        <Card.Img className='card-image' variant="top" src="https://lh3.googleusercontent.com/p/AF1QipPcV229Z_rAU8PUhNHUY0SiMusWmmbEeevDzHQc=s1360-w1360-h1020-rw" />
      <Card.Body>
        <Card.Title>Power play academy</Card.Title>
        <Card.Text>
          price:14000
        </Card.Text>
        {/* Add more content or buttons as needed */}
        {/* <Button variant="primary" onClick={handleBookClick}>Book</Button> */}
      </Card.Body>
        </Card>
      </Carousel.Item>

      <Carousel.Item>
        <Card>
        <Card.Img className='card-image' variant="top" src="https://lh3.googleusercontent.com/p/AF1QipMoCBGnMPSU0nzWuSy_4V1ms6_3_wb-xr6oMmXe=s1360-w1360-h1020" />
      <Card.Body>
        <Card.Title>Crictown</Card.Title>
        <Card.Text>
          price:12000
        </Card.Text>
        {/* Add more content or buttons as needed */}
        {/* <Button variant="primary" onClick={handleBookClick}>Book</Button> */}
      </Card.Body>
        </Card>
      </Carousel.Item>

      {/* Add more Carousel.Items for additional cards */}

    </Carousel>
    
  </Container>
);
};
  

export default CardCarousel;