import React from 'react';
import logo from '../../../logo.png'; 
import { googleLogout } from '@react-oauth/google';

const MyNavbar = () => {
  const userData = localStorage.getItem("userData");
  const parsedData = JSON.parse(userData);

  const logOut = () => {
    googleLogout();
    localStorage.removeItem("userData");
    
};
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <a className="navbar-brand p-1" href="/">
        <img
          src={logo} // Replace with the actual path to your logo
          width="150"
          height="50"
          className="d-inline-block align-top"
          alt="Your Logo"
        />
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse justify-content-end p-2" id="navbarNav">
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link" href="/">
              Home
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/about">
              Login
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/services">
              Contact Us
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/services">
              {parsedData?.name??"userName"}
            </a>
          </li> 
          <li className="nav-item">
            <a className="nav-link" onClick={()=>logOut()}>
              Logout
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default MyNavbar;
