import React, { useEffect, useState } from "react";
import "./Signup.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import background from "../../../sportsBackground.jpg";
import logo from "../../../logo.png";
import GoogleLogo from "./google_logo.svg";
import { googleWallet } from "fontawesome";
import { useNavigate } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { useGoogleLogin } from '@react-oauth/google';
import axios from "axios";

function Signup(){
const [user, setUser] = useState([])
const [profile, setProfile] = useState([])
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: "",
    userType: "",
    email: "",
    phone: "",
    password: "",
    passwordConfirmation: "",
  });
 

  // console.log(jwtDecode(ya29.a0AXooCgvTjvxX5Kabvl5EBYDOwbxcXrLUJNiBpNAdNN-kIagihhClNBFDZHuZwe3qeYjkQgX3O25Y3s0QVzKYXYUe2udGPHBZiyMQiFjijBkrkfMoqOiOYYi3Y6hvqbOs7DX2Gt-KcpAjbUrkw0d2WwBbVo4DBoA6wcUaCgYKAUMSARESFQHGX2MifW_AIuhg02EjnUFdGzqkOA0170))
  console.log(profile);
  const handleSubmite = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://new.bookmygrounds.in/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify(formData),
      });

      if (response.ok) {
        // Handle successful registration
        console.log("Registration successful!");
        // Redirect to login page or any other page
        navigate("/login");
      } else {
        // Handle registration failure
        console.error("Registration failed.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const login = useGoogleLogin({
    ux_mode: "redirect",
    onSuccess: (codeResponse) => {
      setUser(codeResponse);
    },
    onError: (error) => console.log('Login Failed:', error),
  });
  useEffect(() => {
    if (user) {
      axios
        .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user?.access_token}`, {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            Accept: 'application/json',
          },
        })
        .then((res) => {
          setProfile(res.data);
          localStorage.setItem("userData", JSON.stringify(res.data));
      navigate("/ownerLandingPage"); 

        })
        .catch((err) => console.log(err));
    }
  }, [user]);


console.log(profile);
  return (
    <>
      <div className="container">
        <div className="row py-1 align-items-center">
          <div className="col-md-5 pr-lg-5  mb-md-0">
            <img
              src="https://bootstrapious.com/i/snippets/sn-registeration/illustration.svg"
              alt=""
              className="img-fluid mb-3 d-md-block"
            />
            <h1 style={{ textAlign: "center" }}> Create an Account</h1>
          </div>

          <div className="col-md-7 col-lg-6 ml-auto">
            <form >
              <div className="row">
                <div className="input-group col-lg-6 mb-4">
                  {/* <div className="input-group-prepend">
                            <span className="input-group-text bg-white px-4 border-md border-right-0">
                                <i className="fa fa-user text-muted"></i>
                            </span>
                        </div> */}
                  <input
                    id="firstName"
                    type="text"
                    name="username"
                    placeholder="username"
                    className="form-control bg-white border-left-0 border-md"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="input-group col-lg-6 mb-4">
                  {/* <div className="input-group-prepend">
                            <span className="input-group-text bg-white px-4 border-md border-right-0">
                                <i className="fa fa-user text-muted"></i>
                            </span>
                        </div> */}
                  <input
                    id="lastName"
                    type="text"
                    name="userType"
                    placeholder="userType"
                    className="form-control bg-white border-left-0 border-md"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="input-group col-lg-12 mb-4">
                  {/* <div className="input-group-prepend">
                            <span className="input-group-text bg-white px-4 border-md border-right-0">
                                <i className="fa fa-envelope text-muted"></i>
                            </span>
                        </div> */}
                  <input
                    id="email"
                    type="email"
                    name="email"
                    placeholder="Email Address"
                    className="form-control bg-white border-left-0 border-md"
                    onChange={handleInputChange}
                  />
                </div>
                {/* <div className="input-group col-lg-12 mb-4">
                                    <div className="input-group-prepend">
                            <span className="input-group-text bg-white px-4 border-md border-right-0">
                                <i className="fa fa-phone-square text-muted"></i>
                            </span>
                        </div>
                                    <span id="countryCode" name="countryCode" className="custom-select form-control bg-white border-left-0 border-md h-100 font-weight-bold text-muted ">+91
                                    </span>
                                    <input id="phoneNumber" type="tel" name="phone" placeholder="Phone Number" className="form-control bg-white border-md border-left-0 pl-3" />
                                </div> */}
                <div className="input-group col-lg-12 mb-4">
                  <div className="input-group-prepend">
                    <span
                      id="countryCode"
                      name="countryCode"
                      className="custom-select form-control bg-white border-right-0 border-md h-100 font-weight-bold text-muted col-lg-3 col-md-3 col-sm-3 col-3"
                      onChange={handleInputChange}
                    >
                      +91
                    </span>
                  </div>
                  <input
                    id="phoneNumber"
                    type="tel"
                    name="phone"
                    placeholder="Phone Number"
                    className="form-control bg-white border-md border-left-0 pl-3 col-lg-9 col-md-9 col-sm-9 col-9"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="input-group col-lg-6 mb-4">
                  {/* <div className="input-group-prepend">
                            <span className="input-group-text bg-white px-4 border-md border-right-0">
                                <i className="fa fa-lock text-muted"></i>
                            </span>
                        </div> */}
                  <input
                    id="password"
                    type="password"
                    name="password"
                    placeholder="Password"
                    className="form-control bg-white border-left-0 border-md"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="input-group col-lg-6 mb-4">
                  {/* <div className="input-group-prepend">
                            <span className="input-group-text bg-white px-4 border-md border-right-0">
                                <i className="fa fa-lock text-muted"></i>
                            </span>
                        </div> */}
                  <input
                    id="passwordConfirmation"
                    type="text"
                    name="passwordConfirmation"
                    placeholder="Confirm Password"
                    className="form-control bg-white border-left-0 border-md"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group col-lg-12  mb-0 col-sm-12">
                  <button
                    type="submit"
                    className="btn btn-primary btn-block py-2 create-account-btn"
                  >
                    <span className="font-weight-bold create-account">
                      Create your account
                    </span>
                  </button>
                </div>
                <div className="form-group col-lg-12 mx-auto d-flex align-items-center my-4">
                  <div className="border-bottom w-100 ml-5"></div>
                  <span className="px-2 small text-muted font-weight-bold text-muted">
                    OR
                  </span>
                  <div className="border-bottom w-100 mr-5"></div>
                </div>
                <div className="form-group col-lg-12 mx-auto col-md-12 col-sm-12 col-xs-12 ">
                  <a className="btn  btn-block btn-outline-secondary py-2 d-flex align-items-center btn-google google-signin">

                    <span
                      className="font-weight-bold flex-grow-1  text-center"
                      onClick={() => login() }
                    >
                    <img src={GoogleLogo} width="30" height="30" className="mr-3"/>
                      Continue with Google
                    </span>
                  </a>
                </div>

                {/* <div className="form-group col-lg-12 mx-auto col-md-12 col-sm-12 col-xs-12 ">
                 */}
                 
                {/* <GoogleLogin width="1000000px" size="large"

                  onSuccess={(credentialResponse) => {
                    var credentialResponseDecoded = jwtDecode(credentialResponse.credential)
                    setGData(credentialResponseDecoded)
                    console.log(credentialResponseDecoded);
                  }}
                  onError={() => {
                    console.log("Login Failed");
                  }}
                />  */}
               
                <div className="text-center w-100 pt-1">
                  <p className="text-muted font-weight-bold">
                    Already Registered?{" "}
                    <a
                      className="text-primary ml-2"
                      onClick={() => navigate("/login")}
                    >
                      Login
                    </a>
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Signup;
