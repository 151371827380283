// components/BookingForm.js
// components/BookingForm.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const BookingForm = () => {
    // alert("inside booking form")
  const [name, setName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [selectedSlot, setSelectedSlot] = useState('');

  const navigate = useNavigate();

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleMobileNumberChange = (e) => {
    setMobileNumber(e.target.value);
  };

  const handleSlotChange = (e) => {
    setSelectedSlot(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your booking logic here, e.g., send data to server, update state, etc.
    console.log('Booking submitted:', { name, mobileNumber, selectedSlot });
  };

  return (
    <div>
      <h2>Booking Form</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Name:
          <input type="text" value={name} onChange={handleNameChange} required />
        </label>
        <br />
        <label>
          Mobile Number:
          <input type="tel" value={mobileNumber} onChange={handleMobileNumberChange} required />
        </label>
        <br />
        <label>
          Select Slot:
          <select value={selectedSlot} onChange={handleSlotChange} required>
            <option value="">Select a slot</option>
            <option value="morning">Morning</option>
            <option value="afternoon">Afternoon</option>
            <option value="evening">Evening</option>
          </select>
        </label>
        <br />
        <button type="submit">Book Now</button>
      </form>
    </div>
  );
};

export default BookingForm;

